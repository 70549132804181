import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.less';
import { pageMetadataMap, PageType } from '@/shared/app-common';
import Image from 'next/image';
import { useUserProvider } from '@/context/UserProvider';
import { Carousel } from 'antd';
// import { useRouter } from 'next/router';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

import { Playfair_Display } from 'next/font/google';
import { LibraryInfoModel } from '@/types/home';
import { set } from 'nprogress';

const AnimatedText = () => {
  const text = ['Track111111111', 'Competitor2222222222', 'Key3333333333', 'Updates44444444']; // 字符串数组
  const animationDuration = 5; // 动画总时长（秒）

  // 计算总字符数（包含所有字符串）
  const totalChars = text.reduce((acc, word) => acc + word.length, 0);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {text.map((word, wordIndex) => (
        <span key={wordIndex} style={{ display: 'inline-block', marginRight: '8px' }}>
          {word.split('').map((char, charIndex) => {
            // 动态计算每个字符的延迟时间
            const currentCharIndex = text
              .slice(0, wordIndex) // 获取当前单词之前的所有单词
              .reduce((acc, w) => acc + w.length, 0) + charIndex; // 当前字符的全局索引

            const delay = (currentCharIndex / totalChars) * animationDuration;

            return (
              <span
                key={charIndex}
                style={{
                  display: 'inline-block',
                  animation: `fadeIn ${animationDuration}s ease-in-out ${delay}s infinite`,
                }}
              >
                {char}
              </span>
            );
          })}
        </span>
      ))}
      <style jsx>{`
        @keyframes fadeIn {
          0%,
          100% {
            opacity: 0;
            transform: translateY(-10px);
          }
          50% {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

// 配置字体
const playfairdisplay = Playfair_Display({
  subsets: ['latin'], // 指定需要的字符子集
  weight: ['700'], // 指定需要的字体权重
  display: 'swap', // 优化字体显示的加载方式
});

const contentStyle: React.CSSProperties = {
  margin: 0,
  height: '36px',
  lineHeight: '36px',
  color: '#BAC3D0',
  fontSize: '14px',
};

const LoginStateInfo = ({
  pageType,
  infoData,
  onClick
}: {
  pageType?: PageType,
  infoData?: LibraryInfoModel,
  onClick?: (isDashboard: boolean, value?: string) => void
}) => {
  const [pageMetaData, _] = useState(pageMetadataMap[pageType ?? PageType.Normal])
  const { isLogin } = useUserProvider()
  const [showCarousel, setShowCarousel] = useState(true);
  const [searchValue, setSearchValue] = useState<string>(null);

  const list = useMemo(() => {
    return [
      {
        imgPath: '/images/home/home-AI-Analysis-icon.webp',
        title: 'AI Analysis',
      },
      {
        imgPath: '/images/home/home-Targeted-Monitoring-icon.webp',
        title: 'Targeted Monitoring',
      },
      {
        imgPath: '/images/home/home-Instant-Alerts-icon.webp',
        title: ('Instant Alerts'),
      },
      {
        imgPath: '/images/home/home-Visual-Result-Comparison-icon.webp',
        title: ('Visual Result Comparison'),
      },
    ]
  }, [])

  const onSubmit = () => {
    setTimeout(() => {
      onClick && onClick(false, searchValue?.trim())
    }, 1);
  }

  const getContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <div className={styles.topContent}>

          <h1 style={{ display: 'none', width: '0px', height: '0px' }}>
            {`Your Best Page Monitor for Ongoing Website Changes`}
          </h1>

          <div className={styles.title}>{`Your Best Page Monitor for`}</div>
          <div className={styles.title}>
            &nbsp;
            {`Ongoing Website Changes`
              .split(' ') // 先按单词分割
              .map((word, wordIndex) => (
                <span key={wordIndex} style={{ display: 'inline-block' }}>
                  {word.split('').map((char, charIndex) => (
                    <span
                      key={charIndex}
                      style={{
                        animationDelay: `${(wordIndex * 10 + charIndex) * 0.1}s`, // 每个字符的动画延迟
                      }}
                    >
                      {char}
                    </span>
                  ))}
                  <span
                    style={{
                      animationDelay: `${(wordIndex * 10 + word.length) * 0.1}s`,
                    }}
                  >
                    &nbsp;
                  </span>
                </span>
              ))}

          </div>
          <div className={styles.desc}>{`No More Missed Updates: A Page Monitor Lets You Monitor Website Changes 24/7`}</div>
          <div className={styles.desc1}>{`InstantKnow has detected`}</div>
          <div className={styles.valContainer}>
            <span className={styles.valCount}>{infoData?.website_num ?? 0}</span>
            <span>{`Projects`}</span>
            <span className={styles.valCount}>{infoData?.url_num ?? 0}</span>
            <span>{`URLs`}</span>
          </div>

          {/* <p>{pageMetaData.titleDescKey}</p> */}

          {/* {isLogin && <span
            className={styles.dashboard}
            onClick={() => {
              onClick && onClick(true, null)
            }}>{`Dashboard`}</span>} */}

          <div className={styles.searchContainer}>
            <Image src={'/images/home/link-icon.webp'} alt='link-icon' width={20} height={20} className={styles.searchIcon} />

            <div className={styles.carouselContainer}>
              <input
                autoFocus
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e?.target.value)}
                className={styles.inputContainer}
                placeholder="Enter the product page you want to stay updated on"
                onBlur={(e) => {
                  e?.stopPropagation()
                  setShowCarousel(true)
                }}
              />

              {/* {showCarousel && <Carousel
                dotPosition="left"
                dots={false}
                autoplay
              >
                {['product page',
                  'social media',
                  'website',
                  'news',
                  'event page'].map((item, index) => {
                    return <div
                      className={styles.carouselItem}
                      key={index}
                      onClick={(e) => {
                        e?.stopPropagation()
                        setShowCarousel(false)
                      }}>
                      <p style={contentStyle}>
                        Enter a <span style={{ color: '#1E5EFF' }}>{item}</span> URL:
                      </p>
                    </div>
                  })}
              </Carousel>} */}
            </div>

            <span className={styles.go} onClick={onSubmit}>
              {`Go >`}
            </span>
          </div>
        </div>

        <div className={styles.bottomContent}>
          {list?.map((item, index) => {
            return <div className={styles.listItem} key={item?.title}>
              <Image src={item?.imgPath} alt={''} width={48} height={48} />
              <span>{item?.title}</span>
            </div>
          })}
        </div>
      </div>
    );
  }, [pageMetaData, isLogin, showCarousel, searchValue]);

  return <>
    {getContent}
  </>;
};

export default LoginStateInfo;
