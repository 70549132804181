import React, { useMemo } from 'react';
// import { GlobalHeader, GlobalFooter } from '@/components/Layouts';
import { useUserProvider } from '@/context/UserProvider';
import { AppSettings, PageType } from '@/shared/app-common';
import { EventName } from '@/shared/event-types';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useWindowSize } from 'react-use';
import './index.less';

import dynamic from 'next/dynamic';
import Head from 'next/head';

const GlobalHeader = dynamic(() => import('@/components/Layouts/GlobalHeader'), { ssr: true });
const GlobalFooter = dynamic(() => import('@/components/Layouts/GlobalFooter'), { ssr: true });

interface LayoutProps {
  head?: React.ReactNode;
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  pageType?: PageType;
}
interface TDKData {
  title?: string;
  description?: string;
  keywords?: string;
}

const Layout = ({
  children,
  head,
  showHeader = true,
  showFooter = true,
  pageType = PageType.Normal,
}: LayoutProps) => {
  // const { isMobile } = useConfigProvider();
  const { isLogin } = useUserProvider();
  const { t } = useTranslation();
  // startData和其他样式区分
  const router = useRouter();
  // const ref = useRef(null);
  const { width } = useWindowSize();

  // 获取当前路径
  const currentPath = router.asPath;
  const currentLocale = router.locale;
  const { data: tdkData, isLoading } = useQuery<TDKData>({
    queryKey: ['tdk', currentPath, currentLocale],
    staleTime: 1000 * 60 * 5,
    refetchOnMount: false,
  });
  const tdkContent = useMemo(() => {
    if (!tdkData || Object.keys(tdkData).length === 0) return null;

    return (
      <Head>
        {tdkData?.title && <title key="title">{tdkData?.title}</title>}
        {tdkData?.description && (
          <meta key="description" name="description" content={tdkData?.description} />
        )}
        {tdkData?.keywords && <meta key="keywords" name="keywords" content={tdkData?.keywords} />}
      </Head>
    );
  }, [tdkData]);

  // 处理 head 中的 TDK，移除其中的 title 和 meta 标签
  const processedHead = useMemo(() => {
    if (!head || !tdkData || Object.keys(tdkData).length === 0) return head;

    // 如果 head 是 React 元素，需要处理其子元素
    const headElement = head as React.ReactElement;
    if (headElement.type === Head) {
      const children = React.Children.toArray(headElement.props.children);
      // 过滤掉 title 和 指定的 meta 标签
      const filteredChildren = children.filter((child) => {
        if (!React.isValidElement(child)) return true;
        if (child.type === 'title' && tdkData.title) {
          return false;
        }
        if (child.type === 'meta') {
          if (child.props.name === 'description' && tdkData.description) {
            return false;
          }
          if (child.props.name === 'keywords' && tdkData.keywords) {
            return false;
          }
        }
        return true;
      });

      return <Head>{filteredChildren}</Head>;
    }
    return head;
  }, [head, tdkData]);

  const getMarginTop = useMemo(() => {
    let topMarginTop = '62px';
    if (width < 900) {
      return topMarginTop;
    }

    if (!showHeader) {
      topMarginTop = '0px';
    } else {
      if (isLogin) {
        topMarginTop = '62px';
      } else {
        topMarginTop = '105px';
      }
    }

    return topMarginTop;
  }, [isLogin, showHeader, width]);

  // app sub title
  const getNavItems = useMemo(() => {
    return [
      {
        text: t('Features'),
        path: '/#features',
        gaName: EventName.header_Home_Features,
      },
      {
        text: t('Use Cases'),
        path: '/#use-cases',
        gaName: EventName.header_Home_Use_Cases,
      },
      {
        text: t('Pricing'),
        path: `/pricing`,
        gaName: EventName.header_Plan,
      },
      {
        text: t('KnowBase'),
        path: `/datify`,
        gaName: EventName.header_Datify,
        dropdownItems: [
          {
            text: <div>{t('Datify')}</div>,
            path: `/datify`,
            gaName: EventName.header_sub_Datify,
          },
          {
            text: <div>{t('Categories')}</div>,
            path: `/categories`,
            gaName: EventName.header_sub_Category,
          },
        ],
      },
      {
        text: t('blog'),
        path: `/blog`,
        gaName: EventName.header_Blog,
      },
    ];
  }, [AppSettings.type]);

  const headerContent = useMemo(() => {
    if (width < 900 && router.pathname.startsWith('/dashboard')) {
      return (
        <header className={'header'}>
          <GlobalHeader isMobile={width < 900} navItems={getNavItems} />
        </header>
      );
    }

    return (
      <>
        {showHeader && (
          <header className={'header'}>
            <GlobalHeader isMobile={width < 900} navItems={getNavItems} />
          </header>
        )}
      </>
    );
  }, [showHeader, width, getNavItems, router.pathname]);

  const footerContent = useMemo(() => {
    return (
      <>
        {showFooter && (
          <footer className={'footer'}>
            <GlobalFooter pageType={pageType} />
          </footer>
        )}
      </>
    );
  }, [showFooter, pageType]);

  return (
    <div className={'layout'}>
      {processedHead}
      {tdkContent}
      {headerContent}
      <main
        // ref={ref}
        style={{ marginTop: getMarginTop }}
        className={classNames('main', {
          mobile: width < 900,
        })}
      >
        {children}
      </main>
      {footerContent}
    </div>
  );
};
export default Layout;
