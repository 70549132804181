import Image from 'next/image'
import styles from './index.module.less'
import { imgUrl } from '@/shared/utils'
import { commonImgPath } from '@/shared/app-common'
import { useMemo } from 'react'


export default function HomeFeatureUI({ onClick }: { onClick?: () => void }) {

  const listData = useMemo(() => {
    return [
      {
        title: 'Stay Updated on Price Changes >',
        desc: 'Monitoring competitor prices helps you stay competitive and maximize profits. Use our page monitor for real-time updates and instant alerts, so you can adjust your strategy and stay ahead in the market.',
        icon: '/images/home/feature-icon-1.webp'
      },
      {
        title: 'Stay Ahead with Policy Changes >',
        desc: 'Policy shifts can change the market landscape. Track updates with our page monitor to respond effectively and maintain a competitive edge.',
        icon: '/images/home/feature-icon-2.webp'
      },
      {
        title: 'Track Competitor Web Design Changes >',
        desc: `Stay competitive with our page monitor by tracking your competitors' web design changes. Learn from their updates, enhance your site's look, and boost customer engagement.`,
        icon: '/images/home/feature-icon-3.webp'
      },
      {
        title: 'Track Discounts and Special Offers >',
        desc: `Monitor homepage changes during key sales seasons or holidays. Spot special promotions and adjust your business strategy to align with market trends.`,
        icon: '/images/home/feature-icon-4.webp'
      },
    ]
  }, [])

  return (
    <div className={styles.container}>

      <div className={styles.topContainer}>
        <h2>{`Do More with Less Effort: Page Monitor Made Simple`}</h2>
        <span>{`Explore How InstantKnow Tracks Page Changes for Every Need`}</span>
      </div>

      <div className={styles.grid}>
        <div className={styles.leftColumn} onClick={onClick}>
          <div className={styles.icon}>
            <Image src={'/images/home/feature-icon-0.webp'} alt='' width={40} height={40} />
          </div>
          <h2 className={styles.title}>{`Smart Competitor Monitoring`}</h2>
          <p className={styles.description}>{`Monitor competitor changes instantly with our reliable page monitor. Stay informed, adapt quickly, and lead your market with accurate business intelligence.`}</p>

          <div className={styles.imageContainer}>
            <Image
              src={imgUrl((commonImgPath + '/home-feature-icon.webp'), 'big')}
              alt="Dashboard preview"
              width={400}
              height={200}
              className={styles.image}
            />
          </div>
        </div>
        <div className={styles.rightColumn}>
          {listData.map((feature, index) => (
            <div key={index} className={styles.featureCard} onClick={onClick}>
              <div className={styles.iconTitle}>
                <Image src={feature?.icon} alt='' width={40} height={40} />
                <div className={styles.cardTitle}>{feature.title}</div>
              </div>
              <div className={styles.cardDescription}>
                <h3 className={styles.cardTitle}>{feature.title}</h3>
                <p className={styles.cardDescriptionText}>{feature.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

