import ConfigProvider from '@/context/ConfigProvider';
import UserProvider, { useUserProvider } from '@/context/UserProvider';
import '@/lib/hooks/common/firebaseConfig/init';
import { AppSettings, kTmpUidKey } from '@/shared/app-common';
import { CurrentLanguage } from '@/shared/language';
import '@/styles/fonts.css';
import '@/styles/globals.less';
import '@/styles/mixins.less';
import withTheme from '@/theme';
import { App as AntdApp } from 'antd';
import { Locale } from 'antd/lib/locale';
import Cookies from 'js-cookie';
import type { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import '../styles/globals.less'; // 引入你的全局样式

import { getTdkApi } from '@/lib/service/tdk';
import Clarity from '@microsoft/clarity';
import {
  dehydrate,
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { debounce } from 'lodash-es';
import { Montserrat } from 'next/font/google';

// 配置字体
const montserrat = Montserrat({
  subsets: ['latin'], // 指定需要的字符子集
  weight: ['400', '500', '600', '700'], // 指定需要的字体权重
  display: 'swap', // 优化字体显示的加载方式
});

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeError', NProgress.done);
Router.events.on('routeChangeComplete', NProgress.done);

require('../styles/ika-theme.less');

const Login = dynamic(() => import('@/components/Login'), { ssr: false });
const CookieConsentContent = dynamic(() => import('@/components/Common/CookieConsent'), {
  ssr: false,
});

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<
  P = {
    dehydratedState?: unknown;
  },
> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [loc, setLoc] = useState<Locale>();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();
  // const { isShowArticleBottomAlert } = useConfigProvider();
  const { isLogin } = useUserProvider();
  const queryClient = new QueryClient();

  useEffect(() => {
    if (isLogin) {
      Cookies.remove(kTmpUidKey);
    } else {
      const storedUuid = Cookies.get(kTmpUidKey);

      if (!storedUuid) {
        const newUuid = uuidv4();
        Cookies.set(kTmpUidKey, newUuid);
      }
    }
  }, [isLogin]);

  useEffect(() => {
    setLoc(CurrentLanguage(router.locale).locale);
  }, [router.locale]);

  useEffect(() => {
    const debounceInit = debounce(() => Clarity.init(AppSettings.clarityId), 3000);
    debounceInit();
  }, []);

  return withTheme(
    <AntdApp>
      <ConfigProvider>
        <UserProvider>
          <Head>
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
            />
          </Head>
          <QueryClientProvider client={queryClient}>
            <HydrationBoundary state={pageProps.dehydratedState}>
              <main className={montserrat.className}>
                {getLayout(<Component {...pageProps} />)}
                <Login />
                {/* <LoginResult /> */}
                {/* <ArticleBottomAlert /> */}
                <CookieConsentContent />
              </main>
            </HydrationBoundary>
          </QueryClientProvider>
        </UserProvider>
      </ConfigProvider>
    </AntdApp>,
    loc,
  );
}

App.getInitialProps = async ({ ctx }: any) => {
  const queryClient = new QueryClient();

  // 预取 TDK 数据
  await queryClient.prefetchQuery({
    queryKey: ['tdk', ctx.pathname, ctx.locale],
    queryFn: async () => {
      const res = await getTdkApi(ctx.pathname, ctx.locale);
      return res?.data ?? {};
    },
  });

  return {
    pageProps: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};

export default appWithTranslation(App);
