
import Link from "next/link"
import styles from './index.module.less'
import { AppSettings, pageMetadataMap, PageType } from "@/shared/app-common"
import Image from "next/image"
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import classNames from "classnames";

const GlobalFooter = ({
  show = true,
  className,
  pageType,
}: {
  show?: boolean;
  className?: string;
  pageType: PageType;
}) => {
  const { t } = useTranslation('common');
  const router = useRouter();

  if (!show) {
    return <></>;
  }

  const cls = classNames(styles.footer, className);

  return (
    <footer className={cls}>
      <div className={styles.container}>

        <div className={styles.logoSection}>
          <div className={styles.logo}>
            <div className={styles.logoIcon} >
              <Image
                src={AppSettings.logoPath}
                alt={AppSettings.name}
                loading="lazy"
                width={32}
                height={32}
              />
            </div>
            <h2>Instant Know</h2>
          </div>

          <p>{t("The best tool for monitoring and analyzing")}</p>

          <div className={styles.socialContainer}>
            <p>{t("Follow us")}</p>
            <div className={styles.socialLinks}>
              <Link href="https://x.com/InstantKnowIK" target="__block">
                <Image src="/images/layouts/x-link-icon.webp" alt="x" width={32} height={32} />
              </Link>
              <Link href="https://www.facebook.com/profile.php?id=61569090703388" target="__block">
                <Image src="/images/layouts/fb-link-icon.webp" alt="facebook" width={32} height={32} />
              </Link>
            </div>
          </div>

        </div>

        <div className={styles.linksGrid}>
          <div className={styles.linkColumn}>
            <h3>{t("Product").toLocaleUpperCase()}</h3>
            <ul>
              <li><Link href={`/#features`}>{t('Features')}</Link></li>
              <li><Link href={`/#use-cases`}>{t('Use Cases')}</Link></li>
              <li><Link href={`/pricing`}>{t('Pricing')}</Link></li>
              <li><Link href="https://docs.google.com/forms/d/e/1FAIpQLSdMTeLdET-QegXUNZiaJM-CCjtKRbOX7biFREodC8mZnqbPRw/viewform" target="_blank">{t("Contact Us")}</Link></li>
            </ul>
          </div>

          <div className={styles.linkColumn}>
            <h3>{t("Features").toLocaleUpperCase()}</h3>
            <ul>

              {Object.entries(PageType)
                .filter(([, value]) => value !== pageType)
                .map(([key, value]) => {
                  const path = `${router.locale && router.locale !== 'en' ? `/${router.locale}` : ''
                    }${value === PageType.Normal ? '' : `/${value}`}`;
                  const pageMetaData = pageMetadataMap[value];
                  const content = t(pageMetaData.titleKey);
                  return (
                    <li key={key}><Link href={path}>{content}</Link></li>
                  );
                })}

            </ul>
          </div>

          <div className={styles.linkColumn}>
            <h3>{t("KnowBase").toLocaleUpperCase()}</h3>
            <ul>
              <li><Link href={`/datify`}>{t('Datify')}</Link></li>
              <li><Link href={`/categories`}>{t('Categories')}</Link></li>
            </ul>
          </div>

          <div className={styles.linkColumn}>
            <h3>{t("Free Tools").toLocaleUpperCase()}</h3>
            <ul>
              <li><Link href={`/blog`} >{t("Blog")}</Link></li>
              <li><Link href={`/pricing#faq-content`}>{t("Help Center")}</Link></li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.bottomSection}>
        <p>All rights reserved. © 2024 Instant Know</p>
        <div className={styles.legalLinks}>
          <Link href={`/privacy-policy`}>{t('privacyPolicy')}</Link>
          <Link href={`/terms-of-use`}>{t('Terms of Use')}</Link>
        </div>
      </div>
    </footer>
  )
}

export default GlobalFooter;